import { tap, shareReplay, take } from 'rxjs/operators';
import { PaymentsCacheStore } from './../data/payments-cache.store';
import { ChildBenefitsPaymentOverview } from './../models/child-benefits-payments-overview';
import { Observable, of, combineLatest } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChildBenefitsPayment } from '../models/child-benefits-payment';
import * as dayjs from 'dayjs';
import { RegionEnum } from 'src/app/core/payments/models/region-enum';
import { PortalUserStore } from 'src/app/core/portal-user/data/portal-user.store';
import { UserInfoModel } from 'src/app/core/portal-user/models/user-info.model';

@Injectable()
export class PaymentsService {
  private readonly regularisationTypes = [
    'AD_01', 'AD_02',
    'KG_01', 'KG_02',
    'SP_01', 'SP_02',
    'TK_01', 'TK_02',
    'TO_01', 'TO_02', 'TO_03', 'TO_04', 'TO_05', 'TO_06', 'TO_07', 'TO_08',
  ];
  private fileIds: {[key in keyof typeof RegionEnum]: string};

  constructor(private http: HttpClient, private userStore: PortalUserStore, private cache: PaymentsCacheStore) { }

  getFuturePayments(): Observable<ChildBenefitsPayment[]> {
    return this.http.get<ChildBenefitsPayment[]>(`${environment.portalApiUrl}/Payments/FuturePayment`);
  }

  getPaymentsOverview(fromDate: Date, untilDate: Date, skip: number, numberToTake: number): Observable<ChildBenefitsPaymentOverview[]> {
    return this.http.get<ChildBenefitsPaymentOverview[]>(`${environment.portalApiUrl}/Payments/GetAllPaymentsOverview`, {
      params: {
        fromDate: dayjs(fromDate).format('YYYY-MM-DD'),
        untilDate: dayjs(untilDate).format('YYYY-MM-DD'),
        skip: skip.toString(),
        take: numberToTake.toString(),
        fileIds: this.fileIds ? JSON.stringify(this.fileIds) : '',
      }
    });
  }

  getCompleteOverview = (): Observable<ChildBenefitsPaymentOverview[]> => {
    return this.getPaymentsOverview(dayjs(0).startOf('year').toDate(), dayjs().endOf('year').toDate(), 0, 0);
  }

  isRegularisation(type: string): boolean {
    // Flanders type starting with R_ are regularisations
    if (type.startsWith('R_')) {
      return true;
    }

    return this.regularisationTypes.includes(type);
  }

  isRegularisationPayment(payment: ChildBenefitsPayment): boolean {
    return this.isRegularisation(payment.paymentType);
  }

  public reset() {
    this.cache.resetState();
  }

  public initialize(): Observable<any> {
    this.reset();

    this.userStore.state$.subscribe((userInfo: UserInfoModel) => {
      this.fileIds = userInfo.fileIds;
    });

    const overview$ = this.getCompleteOverview();
    const futurePayments$ = this.getFuturePayments();

    this.cache.setOverview(overview$);
    this.cache.setFuturePayments(futurePayments$);

    const initialize$ = combineLatest([
      this.cache.getOverview(),
      this.cache.getFuturePayments()
    ]).pipe(take(1), shareReplay(1));

    initialize$.subscribe();

    return initialize$;
  }

  public getPaymentTotalAmount(payment: ChildBenefitsPayment): number {
    // NEW WAY: show payment amount for payments, show sum of payment total amounts for regulations.
    if (payment.isRegularisation) {
      const amount = payment.payments.reduce((sum, p) => sum + this.getPaymentTotalAmount(p), 0);
      return parseFloat(amount.toFixed(2));
    }

    return parseFloat(payment.paymentAmount.toFixed(2));

    // OLD WAY: rely on totalamount.
    // This appears to be not so reliable with all the new structures, so instead,
    // we just show the paymentamount, except when it's a regulation.

    // if (payment.totalAmount > 0) {
    //   const result = payment.totalAmount - payment.totalRetained
    //   return parseFloat(result.toFixed(2));
    // }

    // return payment.totalAmount;
  }
}
